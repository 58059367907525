import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import CPic from './Classes-Pic.png';


function Classes() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <p style={{ color: "white" }}>
        <h3 style={{ fontSize: "1.9em" }}>
        Classes <span className="purple"> Boxeo y Defensa </span>   
        </h3>
        </p>
        <p style={{ color: "white" }}>
            ¡Michelle ofrece clases de boxeo, MMA y judo!
        </p>
        <Row className="justify-content-center">
          <Col md={4} className="d-flex justify-content-center">
            <img src={CPic} className="img-fluid" alt="avatar" />
          </Col>
        </Row>


      </Container>
    </Container>
  );
}

export default Classes;
